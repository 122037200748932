import React, {useState} from 'react';

const {Provider, Consumer} = React.createContext()

const EffectsProvider = (props) => {
  const [rotation, setRotation] = useState(0)

  window.addEventListener('scroll', () => {
    setRotation(window.scrollY)
  })
  return (
    <Provider value={{
      rotation,
    }}>
      {props.children}
    </Provider>
  );
};

export const withEffects = C => props => (
  <Consumer>
    {value => <C {...value} {...props} />}
  </Consumer>
)

export default EffectsProvider;