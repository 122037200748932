import React from 'react';
import ResumePng from '../images/resume.png'
const Resume = () => {
  return (
  <>
    
    <img src={ResumePng}/>
  </>
  );
};

export default Resume;