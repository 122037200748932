import React from 'react';
import {withEffects} from '../providers/EffectsProvider'
import styled from 'styled-components'
import cog from '../images/cog.png'
const Cogs = (props) => {
  return (
    <Container>
      
      <div className="allcogs">
      <img id='big-cog' src={cog} />
      <img id='top-cog' src={cog} />
      <img id='bottom-cog' src={cog} />
      </div>
    </Container>
  );
};

const Container = withEffects(styled.div`

  
  p {
    position: sticky; 
    right:0;
    
  }
#big-cog {
    height: 120px;
    bottom: 40px;
    right: 26px;
  position: fixed;
  transform: rotate(${props => props.rotation / 10}deg)
}
#bottom-cog {
  position: fixed;
  transform: rotate(-${props => props.rotation / 10}deg);
  height: 70px;
  bottom: 4px;
    right: 10px;
}
  #top-cog {
  position: fixed;
  transform: rotate(-${props => props.rotation / 10}deg);
  height: 70px;
    right: 10px;
    bottom: 127px;
  }

  @media only screen and (max-width: 1000px) {
    #top-cog {
      position: fixed;
  transform: rotate(${props => props.rotation / 10}deg);
  height: 70px;
    bottom: 36px;
    left: -32px;
    }
    #big-cog {
      display: none;
    }
  
      #bottom-cog {
  position: fixed;
  transform: rotate(-${props => props.rotation / 10}deg);
  height: 70px;
    bottom: 36px;
    right: -32px;
}
    



    }

`)

export default withEffects(Cogs);