import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {BrowserRouter as Router } from 'react-router-dom'
import EffectsProvider from './providers/EffectsProvider'
import ThemeProvider from './providers/ThemeProvider'

import * as serviceWorker from './serviceWorker';

ReactDOM.render(

  <Router>
    <EffectsProvider>
      <ThemeProvider>
        <App />
      </ThemeProvider>
    </EffectsProvider>
  </Router>
  
, document.getElementById('root'));


serviceWorker.unregister();
